import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ResponsiveDialog from "@nualang/nualang-ui-components/dist/Dialogs/ResponsiveDialog";
import DialogContentText from "@mui/material/DialogContentText";
import {
  getUser,
  updateUser,
} from "@nualang/nualang-api-and-queries/APIs/Users";
import {
  invalidateCache,
  DataType,
} from "@nualang/nualang-api-and-queries/APIs/invalidation";
import config from "../../config";

function AcceptTermsDialog({ t, username, data }) {
  const { path, lastUpdated, keyChanges } = config.terms;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        setIsOpen(false);
        await updateUser(username, {
          acceptedTermsDate: new Date().toISOString().slice(0, 10),
        });
        await invalidateCache(DataType.USER, {
          userId: username,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const checkIfTermsHaveUpdated = async () => {
      try {
        if (username) {
          const response = await getUser(username);
          if (response.errorMessage) {
            throw new Error(response.errorMessage);
          }
          if (
            !response.acceptedTermsDate &&
            lastUpdated.slice(0, 10) > "2021-01-29"
          ) {
            // acceptedTermsDate not known, check whether terms have been updated from orginal version '2021-01-29'
            setIsOpen(true);
          } else if (
            response.acceptedTermsDate &&
            lastUpdated.slice(0, 10) > response.acceptedTermsDate
          ) {
            // acceptedTermsDate is known, check whether terms have been updated since they were accepted
            setIsOpen(true);
          }
        }
        return true;
      } catch (error) {
        return false;
      }
    };
    checkIfTermsHaveUpdated();
  }, [lastUpdated, username]);

  return (
    <ResponsiveDialog
      t={t}
      open={isOpen}
      isSubmitDisabled={isLoading}
      dialogTitle={t("updates_to_our_terms_of_service")}
      submitText={t("continue")}
      handleClose={handleSubmit}
      handleSubmit={handleSubmit}
    >
      <DialogContentText className="content terms">
        <p>Hi there,</p>
        <p>
          {/* eslint-disable-next-line max-len */}
          We have updated our{" "}
          <a href={path} target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>
          .
        </p>
        <p>Below are some of the key changes:</p>
        <ul>
          {keyChanges.map((keyChange, i) => (
            <li key={`keyChange${i}`}>{keyChange}</li>
          ))}
        </ul>
        <p>
          {/* eslint-disable-next-line max-len */}
          By continuing to use our services, you acknowledge and accept the
          updated{" "}
          <a href={path} target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>
          .
        </p>
        <p>
          {/* eslint-disable-next-line max-len */}
          We encourage you to read the updated terms to understand how the
          changes affect you. Other than that, you don&apos;t need to take any
          further action.
        </p>
        <p>Thank you for being part of our community,</p>
        <p>The Nualang team</p>
      </DialogContentText>
    </ResponsiveDialog>
  );
}

AcceptTermsDialog.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
  open: PropTypes.bool,
  username: PropTypes.string,
  handleClose: PropTypes.func,
};

export default function AcceptTerms(props) {
  return <AcceptTermsDialog {...props} />;
}
