/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import BotsContext from "./BotsContext";
import withAppContext from "../hoc/withAppContext";
import withUsersContext from "../hoc/withUsersContext";
import {
  sendFeedback as sendFeedbackEmailAPI,
  exerciseCompletion as sendExerciseCompletionEmailAPI,
} from "@nualang/nualang-api-and-queries/APIs/Notifications";

const initialBotState = {
  isLoading: true,
};

class BotsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = initialBotState;
  }

  sendFeedbackEmail = async (
    emails,
    username,
    creatername,
    feedback,
    contentname,
    contenttype,
    link,
    state,
  ) => {
    try {
      let recipients = emails;
      if (recipients.length === 0) {
        return false;
      }
      const email = {
        recipients,
        params: {
          feedback: feedback,
          contentname: contentname,
          contenttype: contenttype,
          username: username,
          creatername: creatername,
          link: link,
          state: state,
        },
      };
      const response = await sendFeedbackEmailAPI(email);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  sendExerciseCompletionEmail = async (
    emails,
    studentname,
    creatername,
    contentname,
    contenttype,
    link,
  ) => {
    try {
      let recipients = emails;
      if (recipients.length === 0) {
        return false;
      }
      const email = {
        recipients,
        params: {
          contentname: contentname,
          contenttype: contenttype,
          studentname: studentname,
          creatername: creatername,
          link: link,
        },
      };
      const response = await sendExerciseCompletionEmailAPI(email);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  render() {
    const { children } = this.props;
    return (
      <BotsContext.Provider
        value={{
          initialBotState,
          sendFeedbackEmail: this.sendFeedbackEmail,
          sendExerciseCompletionEmail: this.sendExerciseCompletionEmail,
        }}
      >
        {children}
      </BotsContext.Provider>
    );
  }
}

BotsProvider.propTypes = {
  usersContext: PropTypes.object,
  appContext: PropTypes.object,
};

export default withAppContext(withUsersContext(BotsProvider));
