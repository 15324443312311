import { signIn, signUp, confirmResetPassword, confirmSignUp, autoSignIn } from "aws-amplify/auth";

const services = {
  async validateCustomSignUp(formData) {
    const errors = {};
    const repeatedOrSequentialChars =
      /(.)\1{2,}|(012|123|234|345|456|567|678|789|890)|(abc|xyz)/i;
    const [possiblyName, domain] = formData.email.split("@");
    const possiblyFirstName = possiblyName.split(".")[0];
    const possiblyLastName = possiblyName.split(".")[1];
    const domainWithoutTLD = domain.split(".")[0];
    const contextSpecificWords = [
      domainWithoutTLD,
      `${possiblyFirstName}`.toLowerCase(),
      `${possiblyLastName}`.toLowerCase(),
    ];
    const cleanContextSpecificWords = contextSpecificWords.filter(
      (str) => str.length >= 3 && str !== "undefined",
    );
    const restrictedWords = cleanContextSpecificWords.concat([
      "nualang",
      "password",
      "pass",
      "word",
    ]);
    const lowercasedPassword = formData.password
      ? formData.password.toLowerCase()
      : undefined;
    if (formData.password && formData.password.length < 10) {
      errors.confirm_password =
        "Passwords must be a minimum 10 characters in length";
    }
    if (!formData.email.includes("@")) {
      errors.acceptTerms = `"${formData.email}" must include @ symbol and must be complete`;
    }
    if (formData.email.includes("@") && !formData.acceptTerms) {
      errors.acceptTerms = "You must agree to the Terms & Conditions";
    }
    if (
      formData.preferred_username &&
      formData.preferred_username.includes("@")
    ) {
      errors.preferred_username = "Name cannot contain @";
    }
    if (
      lowercasedPassword &&
      restrictedWords.some((word) => lowercasedPassword.includes(word))
    ) {
      errors.confirm_password =
        "Password cannot contain your name, username, email domain or other context specific words e.g. 'Password', 'Nualang'";
    }
    if (
      formData.password &&
      formData.password.length >= 10 &&
      repeatedOrSequentialChars.test(formData.password)
    ) {
      errors.confirm_password =
        "Password cannot contain 3 or more repeated characters, sequential numbers, or sequential letters";
    }
    return errors;
  },
  async handleSignIn(formData) {
    const { password } = formData;
    let { username } = formData;
    username = username.toLowerCase();
    return signIn({ username, password} );
  },
  async handleForgotPasswordSubmit({ username, newPassword, confirmationCode}) {
    const formData = {
      email: username,
      password: newPassword,
    };
    const { confirm_password } = await this.validateCustomSignUp(formData);
    if (confirm_password) {
      throw new Error(confirm_password);
    }
    return confirmResetPassword({ username, newPassword, confirmationCode });
  },
  async handleSignUp(formData) {
    const { password, options } = formData;
    let { userAttributes } = options;
    let { username } = formData; 
    username = username.toLowerCase();
    userAttributes.email = userAttributes.email.toLowerCase();
    userAttributes["custom:acceptTerms"] = "true";
    return signUp({
      username,
      password,
      options: {
        userAttributes,
        autoSignIn: true
      },
    });
  },
  async handleConfirmSignUp({ username, confirmationCode }) {
    await confirmSignUp({
      username,
      confirmationCode
    });
    // autoSignIn not triggered automatically with Amplify V6
    await autoSignIn();
  },
};

export default services;
