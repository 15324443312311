import { StrictMode } from "react";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import AppThemeProvider from "./themes/ThemeProviders/AppThemeProvider";
import { createRoot } from "react-dom/client";
import config from "./config";
import { initialize } from "@nualang/nualang-api-and-queries/index.js";
import { initializeAppConfig } from "@nualang/nualang-ui-components/dist/config";

initialize(config);
initializeAppConfig(config);

const root = createRoot(document.getElementById("root"));

root.render(
  // <StrictMode>
  <AppThemeProvider>
    <App />
  </AppThemeProvider>,
  // </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
