/*
Custom version of withAuthenticator,
see: https://github.com/aws-amplify/amplify-ui/blob/main/packages/react/src/components/Authenticator/withAuthenticator.tsx
*/
import React, { useCallback, useEffect } from "react";
import { fetchAuthSession, getCurrentUser, signOut, signInWithRedirect } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import {
  Authenticator,
  Flex,
  Grid,
  useTheme,
  ThemeProvider,
  View,
  Image,
} from "@aws-amplify/ui-react";
// import '@aws-amplify/ui-react/styles.css';
import { Dialog } from "@mui/material";
import components from "./components";
import services from "./services";
import Loading from "../../components/Misc/Loading";
import AmplifyBackgroundImage from "../../img/amplify-background-person-laptop-nualas.jpeg";
import {
  getUsernameFromAuthData,
  trackPageView,
  searchStringToObj,
  objToSearchString,
} from "../../utils/index";

const validIdPs = ["Wayside-SAML"];
const validActions = ["signIn", "signUp", "forgotPassword"];

function AmplifyAuthenticator(props) {
  const { tokens } = useTheme();

  useEffect(() => {
    trackPageView(`${window.location.pathname}${window.location.search}`);
  }, []);

  return (
    <Grid
      templateColumns={{
        base: "0 1fr",
        xl: "1fr 40%",
      }}
    >
      <View height="100vh">
        <Image
          src={AmplifyBackgroundImage}
          alt="Person on laptop with Nualas"
          width="100%"
          height="100%"
          objectFit="cover"
        />
      </View>
      <Flex
        backgroundColor={tokens.colors.background.secondary}
        justifyContent="center"
        style={{
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <ThemeProvider>
          <Authenticator {...props}>
            {(authenticator) => <Loading {...props} {...authenticator} />}
          </Authenticator>
        </ThemeProvider>
      </Flex>
    </Grid>
  );
}

function UnauthenticatedComponentWrapper({
  initialState,
  isAuthDialogOpen,
  setIsAuthDialogOpen,
  formFields,
  options,
  children,
}) {
  return (
    <>
      <Dialog
        open={isAuthDialogOpen}
        fullScreen
        handleClose={() => setIsAuthDialogOpen(false)}
        aria-label="Authentication Dialog"
      >
        <AmplifyAuthenticator
          initialState={initialState}
          components={components}
          services={services}
          signUpAttributes={["preferred_username"]}
          socialProviders={["google"]}
          formFields={formFields}
          {...options}
        />
      </Dialog>
      {children}
    </>
  );
}

export default function withAuthenticator(Component, options) {
  return function WrappedWithAuthenticator(props) {
    const [authState, setAuthState] = React.useState();
    const [userState, setUserState] = React.useState();
    const [loading, setLoading] = React.useState(true);

    console.log("withAuth dev-1");

    const qsParams =
      typeof window !== "undefined"
        ? searchStringToObj(window.location.search)
        : {};
    const initialState = validActions.includes(qsParams.action)
      ? qsParams.action
      : "signIn";
    const userId = qsParams.userId;
    const userEmail = qsParams.email || "";
    const [isAuthDialogOpen, setIsAuthDialogOpen] = React.useState(
      validActions.includes(qsParams.action) ? true : false,
    );
    const [idP, setIdP] = React.useState(
      validIdPs.includes(qsParams.idP) ? qsParams.idP : "",
    );
    const federatedSwitchUserPath = sessionStorage.getItem(
      "federated-switch-user-path",
    );
    const federatedSwitchUserIdPValue = sessionStorage.getItem(
      "federated-switch-user-idp",
    );
    const federatedSwitchUserIdP = validIdPs.includes(
      federatedSwitchUserIdPValue,
    )
      ? federatedSwitchUserIdPValue
      : "";
    const isSwitchingUser = federatedSwitchUserPath && federatedSwitchUserIdP;

    async function setUser() {
      try {
        const { username } = await getCurrentUser();
        let user;
        if (username) {
          const session = await fetchAuthSession({ forceRefresh : true });
          const attributes = session?.tokens?.idToken?.payload;
          user = { username, attributes };
          if (user && user.attributes) {
            const selectedUsername = getUsernameFromAuthData(user);
            // add 'username' to attributes
            user.attributes.username = selectedUsername;
            setUserState({
              ...user,
              username,
            });
            setAuthState("signedin");
            setLoading(false);
          }
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }

    const checkUser = useCallback(() => {
      setUser();
    }, []);

    useEffect(() => {
      if (!window.location.search.includes("code")) {
        if (window.location.search.includes("idP")) {
          /* 
            Dont include the 'idP' param in the current path.
            we don't want it included in the url when the user returns from the idP.
          */
          let searchParams = searchStringToObj(window.location.search);
          delete searchParams["idP"];
          delete searchParams["userId"];
          let searchString =
            objToSearchString(searchParams).length > 0
              ? "?" + objToSearchString(searchParams)
              : "";
          localStorage.setItem(
            "nualang-auth-current-path",
            `${window.location.pathname}${searchString}`,
          );
        } else {
          localStorage.setItem(
            "nualang-auth-current-path",
            `${window.location.pathname}${window.location.search}`,
          );
        }
      }
      // eslint-disable-next-line consistent-return
      Hub.listen("auth", ({ payload }) => {
        if (payload.event === "signedIn") {
          return checkUser();
        }
        if (payload.event === "signedOut") {
          setUserState(null);
          return setLoading(false);
        }
      });
      return checkUser();
    }, [checkUser]);

    const isSignedIn = authState === "signedin" && userState ? true : false;

    function handleFederatedSignIn() {
      try {
        if (
          isSignedIn &&
          userState &&
          userState.username &&
          userState.username.startsWith(idP)
        ) {
          if (`${idP}_${userId}` !== userState.username) {
            /*
            If there is currently a different federated user signed in
            we need to sign them out before signing the new user in.
            We need to keep track of the path since the signOut causes a redirect and we lose the current path.
            */
            sessionStorage.setItem("federated-switch-user-idp", idP);
            sessionStorage.setItem(
              "federated-switch-user-path",
              localStorage.getItem("nualang-auth-current-path"),
            );
            signOut();
          } else {
            let searchParams = searchStringToObj(window.location.search);
            delete searchParams["idP"];
            delete searchParams["userId"];
            window.history.replaceState(
              null,
              "",
              "?" + objToSearchString(searchParams) + window.location.hash,
            );
            setIdP("");
          }
        } else {
          sessionStorage.setItem(
            "last-federated-user-path",
            localStorage.getItem("nualang-auth-current-path"),
          );
          sessionStorage.setItem("last-federated-user-idp", idP);
          signInWithRedirect({ provider: idP });
        }
      } catch (err) {
        console.error(err);
      }
    }

    useEffect(() => {
      let signInTimer;
      const redirectPathQueryParam = (federatedSwitchUserPath || "").includes(
        "?",
      )
        ? `&idP=${federatedSwitchUserIdP}`
        : `?idP=${federatedSwitchUserIdP}`;
      const redirectPath = `${federatedSwitchUserPath}${redirectPathQueryParam}`;
      if (idP && !loading) {
        handleFederatedSignIn();
      } else if (isSwitchingUser && !loading && !isSignedIn) {
        if (
          `${window.location.pathname}${window.location.search}` !==
          redirectPath
        ) {
          sessionStorage.removeItem("federated-switch-user-path");
          sessionStorage.removeItem("federated-switch-user-idp");
          signInTimer = setTimeout(() => {
            window.location.replace(redirectPath);
          }, 500);
        }
      }
      return () => {
        if (signInTimer) {
          clearTimeout(signInTimer);
        }
      };
    }, [idP, loading]);

    useEffect(() => {
      function handleAmplifyError() {
        const cognitoIdentitySP = `CognitoIdentityServiceProvider.${process.env.REACT_APP_CLIENT_ID}`;
        const lastAuthUser = localStorage.getItem(
          `${cognitoIdentitySP}.LastAuthUser`,
        );
        if (lastAuthUser) {
          const userDataItemName = `${cognitoIdentitySP}.${lastAuthUser}.userData`;
          const userData = localStorage.getItem(userDataItemName);
          if (userData === "{}") {
            /*
            Amplify issue https://github.com/aws-amplify/amplify-js/issues/11106
            Sign out to clear local storage and prevent infinite loading screen
            */
            console.log(
              "Encountered potential amplify error - Clearing userData in localstorage",
            );
            localStorage.removeItem(userDataItemName);
            // const lastUserIdP = sessionStorage.getItem('last-federated-user-idp');
            // const lastUserPath = sessionStorage.getItem('last-federated-user-path');
            // if (lastUserIdP && lastUserPath) {
            //   // Set following values to retry sign in once the sign out has ended
            //   sessionStorage.setItem('federated-switch-user-idp', lastUserIdP);
            //   sessionStorage.setItem('federated-switch-user-path', lastUserPath);
            // }
            // signOut();
          }
        }
      }
      handleAmplifyError();
    }, []);

    // show loading screen while fetching, otherwise return page
    if (loading || idP || isSwitchingUser) return <Loading />;

    return isSignedIn ? (
      <Component authData={userState} authenticated={true} {...props} />
    ) : (
      <>
        <UnauthenticatedComponentWrapper
          setIsAuthDialogOpen={setIsAuthDialogOpen}
          isAuthDialogOpen={isAuthDialogOpen}
          options={options}
          initialState={initialState}
          formFields={
            userEmail
              ? {
                  signIn: {
                    username: {
                      defaultValue: userEmail,
                    },
                  },
                  signUp: {
                    email: {
                      defaultValue: userEmail,
                    },
                  },
                }
              : null
          }
        >
          <Component
            authData={{ attributes: {} }}
            setIsAuthDialogOpen={setIsAuthDialogOpen}
            isAuthDialogOpen={isAuthDialogOpen}
            authenticated={false}
            {...props}
          />
        </UnauthenticatedComponentWrapper>
      </>
    );
  };
}
