import React from "react";

const Welcome = React.lazy(() => import("./views/Welcome"));
const Payment = React.lazy(() => import("./views/Payment"));
const StartTrial = React.lazy(() => import("./views/StartTrial"));
const Error = React.lazy(() => import("./views/DefaultError"));

const Dashboard = React.lazy(() => import("./views/Dashboard"));

const Activity = React.lazy(() => import("./views/Activity"));
const CourseActivity = React.lazy(() => import("./views/Activity/Course"));
const SectionActivity = React.lazy(
  () => import("./views/Activity/Course/Section"),
);
const TopicActivity = React.lazy(
  () => import("./views/Activity/Course/Section/Topic"),
);
const ExerciseActivity = React.lazy(
  () => import("./views/Activity/Course/Section/Topic/Exercise"),
);
const ExerciseAttemptActivity = React.lazy(
  () => import("./views/Activity/Course/Section/Topic/Exercise/Attempt"),
);
const BotActivity = React.lazy(() => import("./views/Activity/Bot"));
const BotAttemptActivity = React.lazy(
  () => import("./views/Activity/Bot/Attempt"),
);
const RoleplayActivity = React.lazy(() => import("./views/Activity/Roleplay"));
const RoleplayAttemptActivity = React.lazy(
  () => import("./views/Activity/Roleplay/Attempt"),
);
const ClassroomActivity = React.lazy(
  () => import("./views/Activity/Classroom"),
);
const Search = React.lazy(() => import("./views/Search/Search"));

const Courses = React.lazy(() => import("./views/Courses"));
const SearchCourses = React.lazy(() => import("./views/Courses/SearchCourses"));
const CreateCourse = React.lazy(() => import("./views/Courses/CreateCourse"));
const ViewCourse = React.lazy(() => import("./views/Courses/ViewCourse"));
const CreateTopic = React.lazy(
  () => import("./views/Courses/ViewCourse/CreateTopic"),
);
const ViewTopic = React.lazy(
  () => import("./views/Courses/ViewCourse/ViewTopic"),
);

const CreateRoleplay = React.lazy(
  () => import("./views/Roleplays/CreateRoleplay/CreateRoleplay"),
);
const PlayRoleplay = React.lazy(
  () => import("./views/Roleplays/PlayRoleplay/PlayRoleplay"),
);
const EditRoleplay = React.lazy(
  () => import("./views/Roleplays/EditRoleplay/EditRoleplay"),
);

const Roleplays = React.lazy(() => import("./views/Roleplays/Roleplays"));
const SearchRoleplaysScreen = React.lazy(
  () => import("./views/Roleplays/SearchRoleplays/SearchRoleplays"),
);

const Classrooms = React.lazy(() => import("./views/Classrooms"));
const SearchClassrooms = React.lazy(
  () => import("./views/Classrooms/SearchClassrooms"),
);
const CreateClassroom = React.lazy(
  () => import("./views/Classrooms/CreateClassroom"),
);
const ViewClassroom = React.lazy(
  () => import("./views/Classrooms/ViewClassroom"),
);
const ClassroomExerciseActivity = React.lazy(
  () => import("./views/Activity/Classroom/Course/Section/Topic/Exercise"),
);

const Bots = React.lazy(() => import("./views/Bots"));
const SearchBots = React.lazy(() => import("./views/Bots/SearchBots"));
const PlayBot = React.lazy(() => import("./views/Bots/PlayBot"));
const CreateBot = React.lazy(() => import("./views/Bots/CreateBot"));
const EditBot = React.lazy(() => import("./views/Bots/EditBot"));

const Settings = React.lazy(() => import("./views/Settings"));
const Library = React.lazy(() => import("./views/Library"));

const JoinGroup = React.lazy(() => import("./views/Groups/Join"));

const Profile = React.lazy(() => import("./views/Profile"));
const Contact = React.lazy(() => import("./views/Contact"));

const PlayGame = React.lazy(
  () => import("./views/Classrooms/ViewClassroom/Games/PlayGame"),
);

const GenerateAudioWrapper = React.lazy(
  () => import("./views/GenerateAudio/GenerateAudio"),
);

const pageTitleSuffix = `App | Nualang`;

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", exact: true, name: "home" },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
    title: `Welcome | ${pageTitleSuffix}`,
  },
  {
    path: "/generate-audio",
    name: "generate_audio",
    component: GenerateAudioWrapper,
    title: `Generate Audio | ${pageTitleSuffix}`,
  },
  {
    path: "/payment",
    name: "payment",
    component: Payment,
    title: `Payment | ${pageTitleSuffix}`,
  },
  {
    path: "/start-trial",
    name: "start_trial",
    component: StartTrial,
    title: `Start Trial | ${pageTitleSuffix}`,
  },
  {
    path: "/error/*",
    name: "error",
    component: Error,
    noAuth: true,
    title: `Error | ${pageTitleSuffix}`,
  },
  {
    path: "/explore",
    name: "explore",
    component: Dashboard,
    title: `Explore | ${pageTitleSuffix}`,
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    title: `Search | ${pageTitleSuffix}`,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    title: `Settings | ${pageTitleSuffix}`,
  },
  { path: "/library", name: "library", component: Library },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    noAuth: true,
    title: `Contact | ${pageTitleSuffix}`,
  },
  {
    path: "/profiles",
    exact: true,
    name: "profiles",
    component: Profile,
    title: `Contact | ${pageTitleSuffix}`,
  },
  {
    path: "/profiles/:profileId",
    exact: true,
    name: "profile",
    component: Profile,
    noAuth: true,
    title: `Profile | ${pageTitleSuffix}`,
  },  
  {
    path: "/groups",
    exact: true,
    name: "groups",
    component: Settings,
    title: `Groups | ${pageTitleSuffix}`,
  },
  {
    path: "/groups/:groupId",
    exact: true,
    name: "group",
    component: Settings,
    title: `Group | ${pageTitleSuffix}`,
  },
  {
    path: "/groups/:groupId/join",
    exact: true,
    name: "join_group",
    component: JoinGroup,
    title: `Join Group | ${pageTitleSuffix}`,
  },
  {
    path: "/roleplays",
    exact: true,
    name: "roleplays",
    component: Roleplays,
    title: `Roleplays | ${pageTitleSuffix}`,
  },
  {
    path: "/roleplays/search",
    exact: true,
    name: "search",
    component: SearchRoleplaysScreen,
    noAuth: true,
    title: `Search Roleplays | ${pageTitleSuffix}`,
  },
  {
    path: "/roleplays/create",
    exact: true,
    name: "create_roleplay",
    component: CreateRoleplay,
    title: `Create Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/roleplays/:roleplayId",
    exact: true,
    name: "roleplay",
    component: PlayRoleplay,
    title: `Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/roleplays/:roleplayId/edit",
    exact: true,
    name: "edit",
    component: EditRoleplay,
    title: `Edit Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/activity",
    exact: true,
    name: "activity",
    component: Activity,
    title: `Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/activity/member",
    exact: true,
    name: "member",
    component: Activity,
    title: `Member Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/activity/member/:memberId",
    exact: true,
    name: "member_activity",
    component: Activity,
    title: `Member Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/activity/member/:memberId/:courseId",
    exact: true,
    name: "course",
    component: Activity,
    title: `Course Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/activity/member/:memberId/:courseId/:sectionId",
    exact: true,
    name: "section",
    component: Activity,
    title: `Section Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/activity/member/:memberId/:courseId/:sectionId/:topicId",
    exact: true,
    name: "topic",
    component: Activity,
    title: `Topic Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/courses",
    exact: true,
    name: "courses",
    component: Courses,
    title: `Courses | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/create",
    exact: true,
    name: "create_course",
    component: CreateCourse,
    title: `Create Course | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/search",
    exact: true,
    name: "search",
    component: SearchCourses,
    noAuth: true,
    title: `Search Courses | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId",
    exact: true,
    name: "course",
    component: ViewCourse,
    noAuth: true,
    title: `Course | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/activity",
    exact: true,
    name: "activity",
    component: CourseActivity,
    title: `Course Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId",
    exact: true,
    name: "section",
    component: ViewCourse,
    noAuth: true,
    title: `Course Section | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/activity",
    exact: true,
    name: "activity",
    component: SectionActivity,
    title: `Section Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/create",
    exact: true,
    name: "create_topic",
    component: CreateTopic,
    title: `Create Topic | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId",
    exact: true,
    name: "topic",
    component: ViewTopic,
    noAuth: true,
    title: `Topic | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/activity",
    exact: true,
    name: "activity",
    component: TopicActivity,
    title: `Topic Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/activity/:exercise",
    exact: true,
    name: "exercise",
    component: ExerciseActivity,
    title: `Exercise Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/activity/:exercise/:memberId",
    exact: true,
    name: "member",
    component: ExerciseActivity,
    title: `Member Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/activity/:exercise/:memberId/:createdAt",
    exact: true,
    name: "attempt",
    component: ExerciseAttemptActivity,
    title: `Exercise Attempt | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/roleplays",
    exact: true,
    name: "roleplays",
    component: ViewTopic,
    noAuth: true,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/roleplays/create",
    exact: true,
    name: "create_roleplay",
    component: CreateRoleplay,
    title: `Create Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/roleplays/:roleplayId",
    exact: true,
    name: "roleplay",
    component: PlayRoleplay,
    title: `Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/roleplays/:roleplayId/edit",
    exact: true,
    name: "edit",
    component: EditRoleplay,
    title: `Edit Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/bots",
    exact: true,
    name: "bots",
    component: ViewTopic,
    noAuth: true,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/bots/create",
    exact: true,
    name: "create_bot",
    component: CreateBot,
    title: `Create Bot | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/bots/:botId",
    exact: true,
    name: "bot",
    component: PlayBot,
    title: `Bot | ${pageTitleSuffix}`,
  },
  {
    path: "/courses/:courseId/:sectionId/:topicId/bots/:botId/edit",
    exact: true,
    name: "edit",
    component: EditBot,
    title: `Edit Bot | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms",
    exact: true,
    name: "classrooms",
    component: Classrooms,
    title: `Classrooms | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/create",
    exact: true,
    name: "create_classroom",
    component: CreateClassroom,
    title: `Create Classroom | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/search",
    exact: true,
    name: "search",
    component: SearchClassrooms,
    noAuth: true,
    title: `Search Classrooms | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId",
    name: "classroom",
    component: ViewClassroom,
    noAuth: false,
    title: `Classroom | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity",
    exact: true,
    name: "activity",
    component: ClassroomActivity,
    title: `Classroom Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/courses",
    exact: true,
    name: "courses",
    component: ClassroomActivity,
    title: `Classroom Courses Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/courses/:courseId",
    exact: true,
    name: "course",
    component: CourseActivity,
    title: `Classroom Course Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/courses/:courseId/:sectionId",
    exact: true,
    name: "section",
    component: CourseActivity,
    title: `Classroom Section Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/courses/:courseId/:sectionId/:topicId",
    exact: true,
    name: "topic",
    component: TopicActivity,
    title: `Classroom Topic Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/courses/:courseId/:sectionId/:topicId/:exercise",
    exact: true,
    name: "exercise",
    component: ClassroomExerciseActivity,
    title: `Classroom Exercise Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/courses/:courseId/:sectionId/:topicId/:exercise/:memberId",
    exact: true,
    name: "member",
    component: ExerciseActivity,
    title: `Classroom Member Exercise Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/courses/:courseId/:sectionId/:topicId/:exercise/:memberId/:createdAt",
    exact: true,
    name: "attempt",
    component: ExerciseAttemptActivity,
    title: `Classroom Member Exercise Attempt | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/member",
    exact: true,
    name: "member",
    component: Activity,
    title: `Classroom Member Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/member/:memberId",
    exact: true,
    name: "member_activity",
    component: Activity,
    title: `Classroom Member Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/member/:memberId/:courseId",
    exact: true,
    name: "course",
    component: Activity,
    title: `Classroom Member Course Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/member/:memberId/:courseId/:sectionId",
    exact: true,
    name: "section",
    component: Activity,
    title: `Classroom Member Section Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/activity/member/:memberId/:courseId/:sectionId/:topicId",
    exact: true,
    name: "topic",
    component: Activity,
    title: `Classroom Member Topic Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/games",
    exact: true,
    name: "games",
    component: ViewClassroom,
    title: `Games | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/games/:gameId",
    exact: true,
    name: "play_game",
    component: PlayGame,
    title: `Play Game | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/create",
    exact: true,
    name: "create_course",
    component: CreateCourse,
    title: `Create Course | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId",
    exact: true,
    name: "course",
    component: ViewCourse,
    title: `Course | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId",
    exact: true,
    name: "topic",
    component: ViewTopic,
    title: `Topic | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/activity",
    exact: true,
    name: "activity",
    component: CourseActivity,
    title: `Course Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId",
    exact: true,
    name: "section",
    component: ViewCourse,
    noAuth: true,
    title: `Course Section | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/activity",
    exact: true,
    name: "activity",
    component: SectionActivity,
    title: `Section Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/create",
    exact: true,
    name: "create_topic",
    component: CreateTopic,
    title: `Create Topic | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId",
    exact: true,
    name: "topic",
    component: ViewTopic,
    noAuth: true,
    title: `Topic | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/activity",
    exact: true,
    name: "activity",
    component: TopicActivity,
    title: `Topic Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/activity/:exercise",
    exact: true,
    name: "exercise",
    component: ExerciseActivity,
    title: `Exercise Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/activity/:exercise/:memberId",
    exact: true,
    name: "member",
    component: ExerciseActivity,
    title: `Member Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/activity/:exercise/:memberId/:createdAt",
    exact: true,
    name: "attempt",
    component: ExerciseAttemptActivity,
    title: `Exercise Attempt | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/roleplays",
    exact: true,
    name: "roleplays",
    component: ViewTopic,
    noAuth: true,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/roleplays/create",
    exact: true,
    name: "create_roleplay",
    component: CreateRoleplay,
    title: `Create Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/roleplays/:roleplayId",
    exact: true,
    name: "roleplay",
    component: PlayRoleplay,
    title: `Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/roleplays/:roleplayId/edit",
    exact: true,
    name: "edit",
    component: EditRoleplay,
    title: `Edit Roleplay | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/bots",
    exact: true,
    name: "bots",
    component: ViewTopic,
    noAuth: true,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/bots/create",
    exact: true,
    name: "create_bot",
    component: CreateBot,
    title: `Create Bot | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/bots/:botId",
    exact: true,
    name: "bot",
    component: PlayBot,
    title: `Bot | ${pageTitleSuffix}`,
  },
  {
    path: "/classrooms/:classroomId/:courseId/:sectionId/:topicId/bots/:botId/edit",
    exact: true,
    name: "edit",
    component: EditBot,
    title: `Edit Bot | ${pageTitleSuffix}`,
  },
  {
    path: "/bots",
    exact: true,
    name: "bots",
    component: Bots,
    title: `Bots | ${pageTitleSuffix}`,
  },
  {
    path: "/bots/search",
    exact: true,
    name: "search",
    component: SearchBots,
    noAuth: true,
    title: `Search Bots | ${pageTitleSuffix}`,
  },
  {
    path: "/bots/create",
    exact: true,
    name: "create_bot",
    component: CreateBot,
    title: `Create Bot | ${pageTitleSuffix}`,
  },
  {
    path: "/bots/:botId",
    exact: true,
    name: "bot",
    component: PlayBot,
    title: `Bot | ${pageTitleSuffix}`,
  },
  {
    path: "/bots/:botId/activity",
    exact: true,
    name: "activity",
    component: BotActivity,
    title: `Bot Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/bots/:botId/activity/:memberId",
    exact: true,
    name: "member",
    component: BotActivity,
    title: `Member Bot Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/bots/:botId/activity/:memberId/:createdAt",
    exact: true,
    name: "attempt",
    component: BotAttemptActivity,
    title: `Member Bot Exercise Attempt | ${pageTitleSuffix}`,
  },
  {
    path: "/roleplays/:roleplayId/activity",
    exact: true,
    name: "activity",
    component: RoleplayActivity,
    title: `Roleplay Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/roleplays/:roleplayId/activity/:memberId",
    exact: true,
    name: "member",
    component: RoleplayActivity,
    title: `Member Roleplay Activity | ${pageTitleSuffix}`,
  },
  {
    path: "/roleplays/:roleplayId/activity/:memberId/:createdAt",
    exact: true,
    name: "Attempt",
    component: RoleplayAttemptActivity,
    title: `Member Roleplay Exercise Attempt | ${pageTitleSuffix}`,
  },
  {
    path: "/bots/:botId/edit",
    exact: true,
    name: "edit",
    component: EditBot,
    title: `Edit Bot | ${pageTitleSuffix}`,
  },
];

export const noAuthPaths = routes
  .filter((route) => route.noAuth === true)
  .map((route) => route.path);

export default routes;
