import React, { useState } from "react";
import PropTypes from "prop-types";
import CoursesContext from "./CoursesContext";
import withAppContext from "../hoc/withAppContext";

import { getCourseSectionTopic } from "@nualang/nualang-api-and-queries/APIs/CourseSectionTopics";
import { createCourseMember } from "@nualang/nualang-api-and-queries/APIs/CourseMembers";
import {
  createCourseReview,
  updateCourseReview,
  deleteCourseReview,
} from "@nualang/nualang-api-and-queries/APIs/CourseReviews";

import {
  invalidateCache,
  DataType,
} from "@nualang/nualang-api-and-queries/APIs/invalidation";
import config from "../config";

import {
  sendCollaboratorEmail as sendCollaboratorEmailAPI,
  sendFeedback as sendFeedbackEmailAPI,
  exerciseCompletion as sendExerciseCompletionEmailAPI,
} from "@nualang/nualang-api-and-queries/APIs/Notifications";

export function CoursesProvider(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasReviewed, setHasReviewed] = useState(false);
  const [updateReview, setUpdateReview] = useState({
    reviewTitle: "",
    reviewContent: "",
    starRating: 0,
  });
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [course, setCourse] = useState({
    courseName: "",
    description: "",
    learnLang: "",
    forLang: "",
    reviews: [],
  });
  const [isCourseCreator, setIsCourseCreator] = useState(false);
  const [isCourseMember, setIsCourseMember] = useState(false);
  const [joinCourseDialogOpen, setIsJoinCourseDialogOpen] = useState(false);
  const [newCourse, setNewCourse] = useState("");

  const joinCourse = async (courseId) => {
    const {
      appContext: { openSnackbar, startLoading, stopLoading },
    } = props;
    try {
      startLoading();
      const response = await createCourseMember(courseId);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }

      // Had to add await since page redirects before firing invalidation requests
      await invalidateCache(DataType.COURSE_JOIN, {
        courseId,
      });

      openSnackbar("joined_course", "success");
      stopLoading();
      setIsCourseMember(true);
      setCourse({ ...course, isMember: true });
      return true;
    } catch (error) {
      openSnackbar("problem", "error");
      stopLoading();
      return false;
    }
  };

  const handleClickCourse = (courseId, enabled) => {
    const tempSelectedCourses = selectedCourses;

    if (enabled) {
      tempSelectedCourses.push(courseId);
    } else {
      const index = tempSelectedCourses.indexOf(courseId);
      tempSelectedCourses.splice(index, 1);
    }
    setSelectedCourses([...tempSelectedCourses]);
  };

  const fetchTopic = async (courseId, sectionId, topicId) => {
    const {
      appContext: { openSnackbar, startLoading, stopLoading },
    } = props;
    try {
      startLoading();
      setIsLoading(true);
      const topic = await getCourseSectionTopic(courseId, sectionId, topicId);
      if (topic.errorMessage) {
        throw new Error(course.errorMessage);
      }
      stopLoading();
      setIsLoading(false);
      return topic;
    } catch (error) {
      console.error(error);
      stopLoading();
      openSnackbar("problem_loading", "error");
      setIsLoading(false);
    }
  };

  const handleClosePrivateCourseDialog = () => setIsJoinCourseDialogOpen(false);

  const resetSelectedCoursesState = () => {
    setSelectedCourses([]);
  };

  const createReview = async (courseId, review) => {
    const {
      appContext: {
        openSnackbar,
        startLoading,
        stopLoading,
        user: { username },
      },
    } = props;
    const { reviewTitle, reviewContent, starRating } = review;
    const type = "course";

    if (!starRating || !reviewContent) {
      openSnackbar("missing_details", "error");
      return;
    }

    if (hasReviewed) {
      try {
        startLoading();
        const body = {
          reviewTitle: reviewTitle,
          reviewContent: reviewContent,
          starRating: starRating,
        };
        const response = await updateCourseReview(courseId, username, body);

        invalidateCache(DataType.REVIEW, {
          courseId,
          createdBy: username,
        });

        const newCourseReviews = course.reviews;
        const reviewIndex = newCourseReviews.findIndex(
          (r) => r.createdBy === username,
        );
        if (reviewIndex > -1) {
          newCourseReviews[reviewIndex] = {
            ...newCourseReviews[reviewIndex],
            ...body,
          };
        }
        setCourse({ ...course, reviews: newCourseReviews });
        setHasReviewed(true);
        setUpdateReview({ ...updateReview, ...body });
        openSnackbar("Review was successfully updated");
        stopLoading();
        return response;
      } catch (error) {
        console.error(error);
        openSnackbar("There was an error updating the review", "error");
        stopLoading();
      }
    } else {
      try {
        startLoading();
        const body = {
          itemID: courseId,
          type: type,
          reviewTitle: reviewTitle,
          reviewContent: reviewContent,
          starRating: starRating,
        };
        const response = await createCourseReview(body);
        setCourse({ ...course, reviews: [...course.reviews, response.Item] });
        setHasReviewed(true);
        setUpdateReview({
          ...updateReview,
          reviewTitle: response.Item.reviewTitle,
          reviewContent: response.Item.reviewContent,
          starRating: response.Item.starRating,
        });

        openSnackbar("Review was successfully created");
        stopLoading();
        invalidateCache(DataType.REVIEW, {
          courseId,
          createdBy: username,
        });
        return response;
      } catch (error) {
        console.error(error);
        openSnackbar("There was an error creating the review", "error");
        stopLoading();
      }
    }
  };

  const deleteReview = async (courseId) => {
    const {
      appContext: {
        openSnackbar,
        startLoading,
        stopLoading,
        user: { username },
      },
    } = props;

    try {
      startLoading();
      const response = await deleteCourseReview(courseId, username);
      setCourse({
        ...course,
        reviews: course.reviews.filter((r) => r.createdBy !== username),
      });
      setHasReviewed(false);
      setUpdateReview({
        ...updateReview,
        reviewTitle: "",
        reviewContent: "",
        starRating: 0,
      });
      openSnackbar("Review was successfully deleted");
      stopLoading();
      invalidateCache(DataType.REVIEW, {
        courseId,
        createdBy: username,
      });
      return response;
    } catch (error) {
      console.error(error);
      openSnackbar("There was an error deleting the review", "error");
      stopLoading();
    }
  };

  const joinPrivateCourse = () => {
    setIsJoinCourseDialogOpen(true);
  };

  const sendCollaboratorEmail = async (
    emails,
    contentName,
    contentType,
    courseCollaborators,
    id,
    creatorName,
  ) => {
    try {
      let recipients = emails;
      recipients = emails.filter(
        (email) => !courseCollaborators.includes(email),
      );
      if (recipients.length === 0) {
        return false;
      }
      const email = {
        recipients,
        params: {
          contentName: contentName,
          contentType: contentType,
          creatorName: creatorName,
          inviteLink: `${config.APP_URL}/courses/${id}?newCollaborator=true`,
        },
      };
      const response = await sendCollaboratorEmailAPI(email);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const sendFeedbackEmail = async (
    emails,
    username,
    creatername,
    feedback,
    contentname,
    contenttype,
    link,
    state,
  ) => {
    try {
      let recipients = emails;
      if (recipients.length === 0) {
        return false;
      }
      const email = {
        recipients,
        params: {
          feedback: feedback,
          contentname: contentname,
          contenttype: contenttype,
          username: username,
          creatername: creatername,
          link: link,
          state: state,
        },
      };
      const response = await sendFeedbackEmailAPI(email);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const sendExerciseCompletionEmail = async (
    emails,
    studentname,
    creatername,
    contentname,
    contenttype,
    link,
  ) => {
    try {
      let recipients = emails;
      if (recipients.length === 0) {
        return false;
      }
      const email = {
        recipients,
        params: {
          contentname: contentname,
          contenttype: contenttype,
          studentname: studentname,
          creatername: creatername,
          link: link,
        },
      };
      const response = await sendExerciseCompletionEmailAPI(email);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <CoursesContext.Provider
      value={{
        newCourse,
        joinCourseDialogOpen,
        setNewCourse,
        joinPrivateCourse,
        isLoading,
        selectedCourses,
        course,
        isCourseCreator,
        isCourseMember,
        hasReviewed,
        updateReview,
        handleClickCourse,
        handleClosePrivateCourseDialog,
        joinCourse,
        fetchTopic,
        resetSelectedCoursesState,
        createReview,
        deleteReview,
        sendCollaboratorEmail,
        sendFeedbackEmail,
        sendExerciseCompletionEmail,
        setSelectedCourses,
      }}
    >
      {props.children}
    </CoursesContext.Provider>
  );
}

CoursesProvider.propTypes = {
  appContext: PropTypes.object,
};

export default withAppContext(CoursesProvider);
